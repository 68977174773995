<template>
  <div class="player-setup-new-prices-available">
    <span>New prices available!</span>
    <Button
      @click="onSimulate"
      :disabled="!simulateBtnActive || isSimulateBtnFrozen"
    >
      <span>Simulate for new prices</span>
    </Button>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Button from '@/components/common/Button';

export default {
  emits: ['onSimulate'],
  components: {
    Button,
  },
  setup(_, { emit }) {
    const store = useStore();
    const route = useRoute();
    const simulateBtnActive = computed(() => {
      if (route.name === 'trading-ui-multiview') {
        return store.getters.multiviewSimulateBySocketChangeEnabled;
      } if (route.name === 'trading-ui') {
        return store.getters.tradingSimulateBySocketChangeEnabled;
      }
      return false;
    });
    const isSimulateBtnFrozen = computed(() => {
      if (route.name === 'trading-ui-multiview') {
        return store.getters.multiviewSimulateBtnFrozen;
      } if (route.name === 'trading-ui') {
        return store.getters.isTradingUIPageFrozen;
      }
      return false;
    });

    const onSimulate = () => {
      emit('onSimulate');
    };

    return {
      simulateBtnActive,
      isSimulateBtnFrozen,
      onSimulate,
    };
  },
};
</script>

<style lang="scss">
.player-setup-new-prices-available {
    height: 48px;
    display: flex;
    gap: 16px;
    padding: 8px 16px;
    align-items: center;
    justify-content: center;
    background-color: #E8EFFF;
    margin-bottom: 5px;
}
</style>
