<template>
  <div
    class="multiview-event-drawer-game-params-tab"
  >
    <div
      v-if="isMultiviewGameParamsTabFrozen"
      class="pricing-loading-overlay"
    >
      <p>Simulating prices - please wait...</p>
    </div>
    <Spinner
      v-if="isMultiviewGameParamsLoading"
      small
    />
    <div
      v-if="!isMultiviewGameParamsLoading"
      class="multiview-event-drawer-game-params-tab--container"
    >
      <div class="params-header-search-actions">
        <div class="game-mode-root">
          {{ isCalculatedMode ? 'Auto mode' : 'Manual mode' }}
          <Tooltip
            :text="isCalculatedMode ? 'Auto mode inputs parameters automatically ' : 'In manual mode user has to input parameter values manually'"
            right
          >
            <Icon
              name="info"
            />
          </Tooltip>
        </div>
        <Button
          variant="secondary"
          icon="link-external"
          @click="goToParamSetup"
        >
          Param manager
        </Button>
        <Button
          variant="secondary"
          icon="modem"
          :disabled="isSimulateDisabled"
          @click="onSimulate"
        >
          <span>Simulate</span>
        </Button>
        <Button
          variant="primary"
          class="publish-btn"
          :class="{ 'disabled': isSubmitDisabled }"
          :icon="'upload'"
          :disabled="isSubmitDisabled"
          @click="onPublish"
        >
          Publish
        </Button>
      </div>
      <div
        class="params-header-tabs"
      >
        <div
          :class="[
            'params-header-tabs-item',
            { 'params-header-tabs-item--is-active': activeTab === 'global' }
          ]"
          @click="setActiveTab('global')"
        >
          <p>Global</p>
        </div>
        <div
          :class="[
            'params-header-tabs-item',
            { 'params-header-tabs-item--is-active': activeTab === 'team' }
          ]"
          @click="setActiveTab('team')"
        >
          Team
        </div>
      </div>
      <div
        v-if="activeTab === 'team'"
        class="params-header-tabs"
      >
        <div
          :class="[
            'params-header-tabs-item',
            { 'params-header-tabs-item--is-active': activeTeamTab === 'both' }
          ]"
          @click="setActiveTeamTab('both')"
        >
          <p>Both teams</p>
        </div>
        <div
          :class="[
            'params-header-tabs-item',
            { 'params-header-tabs-item--is-active': activeTeamTab === 'teamA' }
          ]"
          @click="setActiveTeamTab('teamA')"
        >
          <p>{{ teamParams?.teamA?.name }}</p>
        </div>
        <div
          :class="[
            'params-header-tabs-item',
            { 'params-header-tabs-item--is-active': activeTeamTab === 'teamB' }
          ]"
          @click="setActiveTeamTab('teamB')"
        >
          <p>{{ teamParams?.teamB?.name }}</p>
        </div>
      </div>
      <div class="multiview-event-drawer-game-params-tab--content">
        <GameParams
          v-if="activeTab === 'global'"
          :global-params="globalParams"
          :is-calculated-mode="isCalculatedMode"
          :sport-id="event?.sportId"
          @onParamChange="onParamChange"
          @onParamIncrease="onParamIncrease"
          @onParamDecrease="onParamDecrease"
        />
        <div
          v-if="activeTab === 'global' && (!globalParams.length && event?.sportId !== BASEBALL_ID)"
          class="no-params-message"
        >
          {{ paramSearch ? 'Searched parameters not found.' : 'Currently, there are no parameters.' }}
        </div>
        <ParamsSetupMarkets
          v-if="activeTab === 'global'"
          :event-id="event.id"
        />
        <TeamParams
          v-if="activeTab === 'team'"
          :selected-team="activeTeamTab"
          :team-params="teamParams"
          :search-active="!!paramSearch"
          :is-calculated-mode="isCalculatedMode"
          :sport-id="event?.sportId"
          :global-params="globalParams"
          @onParamChange="onParamChange"
          @onParamIncrease="onParamIncrease"
          @onParamDecrease="onParamDecrease"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, ref,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {
  find, filter, includes, cloneDeep, capitalize,
} from 'lodash';
import Button from '@/components/common/Button';
import Spinner from '@/components/common/Spinner';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';
import {
  getGlobalParamsKeysBySport, getTeamParamsKeysByTeamAndSport,
  isBaseballGameParamsError, isBaseballGameParamsChanged,
  getBasketballGlobalParamsModelType,
} from '@/components/player-setup/params-helper';
import GameParams from './params/GameParams';
import TeamParams from './params/TeamParams';
import ParamsSetupMarkets from '@/components/player-setup/ParamsSetupMarkets';
import sportIds from '@/services/helpers/sports';

const {
  BASEBALL_ID,
} = sportIds;

export default {
  components: {
    Tooltip,
    Button,
    Spinner,
    GameParams,
    TeamParams,
    ParamsSetupMarkets,
    Icon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const activeTab = ref('global');
    const activeTeamTab = ref('both');
    const paramSearch = ref('');
    const multiviewListEvent = computed(() => store.getters.getSingleMultiviewListEvent(props.event.id));
    const eventParams = computed(() => multiviewListEvent.value?.params);
    const isCalculatedMode = computed(() => eventParams.value?.selectedParamSource === 'CALCULATED');
    const gameParams = computed(() => eventParams.value?.gameParams);
    const globalParamsModelType = computed(() => getBasketballGlobalParamsModelType(props.event?.competitionType));
    const globalParamsKeys = computed(() => getGlobalParamsKeysBySport(props.event.sportId, globalParamsModelType.value));
    const matchState = computed(() => multiviewListEvent.value?.matchState);
    const globalParams = computed(() => (props.event?.sportId === BASEBALL_ID
      ? gameParams.value
      : filter(
        filter(
          gameParams.value, (param) => includes(globalParamsKeys.value, param.key)
          && (includes(param.availableInMatchStates, matchState.value) || !param.availableInMatchStates),
        ), (param) => includes(param.longName.toLowerCase(), paramSearch.value.toLowerCase()),
      )));
    const gameMarkets = computed(() => store.getters.paramsManagerMarkets);
    const teamParams = computed(() => {
      const { isUSAView, home, away } = props.event;

      const teamA = isUSAView ? away : home;
      const teamB = isUSAView ? home : away;

      const teamAParamsKeys = getTeamParamsKeysByTeamAndSport(capitalize(teamA.side), props.event.sportId, globalParamsModelType.value);
      const teamBParamsKeys = getTeamParamsKeysByTeamAndSport(capitalize(teamB.side), props.event.sportId, globalParamsModelType.value);

      return {
        teamA: {
          name: teamA.name,
          params: filter(
            filter(gameParams.value, (param) => includes(teamAParamsKeys, param.key)),
            (param) => includes(param.longName.toLowerCase(), paramSearch.value.toLowerCase()),
          ),
        },
        teamB: {
          name: teamB.name,
          params: filter(
            filter(gameParams.value, (param) => includes(teamBParamsKeys, param.key)),
            (param) => includes(param.longName.toLowerCase(), paramSearch.value.toLowerCase()),
          ),
        },
      };
    });
    const isMultiviewGameParamsLoading = computed(() => store.getters.multiviewListParamsLoading);
    const isSimulateDisabled = computed(() => !eventParams.value?.isSimulateEnabled && !store.getters.multiviewSimulateBySocketChangeEnabled);
    const isSubmitDisabled = computed(() => (props.event?.sportId === BASEBALL_ID
      ? !isBaseballGameParamsChanged(gameParams.value) || isBaseballGameParamsError(gameParams.value)
      : !find(gameParams.value, (param) => param.value !== param.originalValue)));
    const isMultiviewGameParamsTabFrozen = computed(() => eventParams.value?.isSimulatingPrices);

    const setActiveTab = (val) => { activeTab.value = val; };
    const setActiveTeamTab = (val) => { activeTeamTab.value = val; };
    const updateParamSearch = (val) => { paramSearch.value = val; };

    const updateGameParams = (updatedParam) => {
      const action = props.event?.sportId === BASEBALL_ID ? 'updateMultiviewListBaseballGameParams' : 'updateMultiviewListGameParams';
      store.dispatch(action, {
        eventId: props.event.id,
        updatedParam,
      });
    };

    const onParamChange = (value, param) => {
      const updatedParam = cloneDeep(param);
      if (param.type === 'boolean' || param.type === 'select' || param.type === 'text-toggle') {
        if (param.type === 'text-toggle' && param.value === value) {
          updatedParam.value = 'NEUTRAL';
        } else {
          updatedParam.value = value;
        }
        updateGameParams(updatedParam);
        return;
      }
      if (value) {
        const paramValue = parseFloat(value);
        const isError = paramValue < param?.minValue || paramValue > param?.maxValue;
        updatedParam.value = paramValue;
        updatedParam.isError = isError;
      } else {
        updatedParam.isError = true;
        updatedParam.value = null;
      }
      updateGameParams(updatedParam);
    };

    const onParamIncrease = (param) => {
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value + updatedParam.raiseIncrement).toFixed(param.numberOfDecimals || 3));
      if (updatedValue <= updatedParam.maxValue) {
        updatedParam.value = updatedValue;
        updateGameParams(updatedParam);
      }
    };

    const onParamDecrease = (param) => {
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value - updatedParam.raiseIncrement).toFixed(param.numberOfDecimals || 3));
      if (updatedValue >= updatedParam.minValue) {
        updatedParam.value = updatedValue;
        updateGameParams(updatedParam);
      }
    };
    const onSimulate = () => store.dispatch('simulateMultiviewGameParams', { eventId: props.event.id });
    const onPublish = () => store.dispatch('submitMultiviewGameParams', { eventId: props.event.id });
    const goToParamSetup = () => {
      const routeData = router.resolve({
        name: 'params-setup',
        params: { eventId: props.event.id },
      });
      window.open(routeData.href, '_blank');
    };

    onMounted(() => {
      if (!gameMarkets.value || !gameMarkets.value.length) {
        store.dispatch('getMultiviewGameMarkets');
      }
    });

    return {
      activeTab,
      paramSearch,
      isMultiviewGameParamsTabFrozen,
      isMultiviewGameParamsLoading,
      isSimulateDisabled,
      isSubmitDisabled,
      globalParams,
      setActiveTab,
      updateParamSearch,
      onSimulate,
      onPublish,
      onParamChange,
      onParamIncrease,
      onParamDecrease,
      teamParams,
      activeTeamTab,
      setActiveTeamTab,
      goToParamSetup,
      isCalculatedMode,
      BASEBALL_ID,
    };
  },
};
</script>

<style lang="scss">
  .multiview-event-drawer-game-params-tab {
    padding: 0 16px 16px 16px;
    height: calc(100% - 64px);
    position: relative;
    overflow: hidden;

    .pricing-loading-overlay {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $gray300;
      z-index: $modalZIndex;
      p {
        padding: 16px;
        background-color: $white;
        width: 300px;
        border-radius: 4px;
      }
    }

    &--container {
      height: 100%;
    }

    .params-header-search-actions {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-top: 3px;
      padding-bottom: 12px;
      position: sticky;
      top: 0;
      z-index: 300;

      .button {
        &.preview-icon {
          .icon {
            margin: 0;
          }
        }
        &.preview-active {
          background-color: $brandPrimary500;
          box-shadow: none;
          .icon {
            svg path {
              fill: $white;
              stroke: none;
            }
          }
        }
      }
      .game-mode-root {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 4px;
        color: $primary500;
        .tooltip {
          z-index: 301;
        }
        .icon {
          svg path {
            stroke: $gray600;
          }
        }
      }
    }

    .params-header-tabs {
      display: flex;
      position: sticky;
      top: 0;
      background: var(--neutral-bg-default-active, #FFF);
      z-index: 101;
      margin-bottom: 16px;

      &-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 32px;
        padding: 0 8px;
        gap: 4px;
        user-select: none;
        cursor: pointer;
        border-bottom: 2px solid var(--primary-stroke-weak-active, #F0F0F0);

        &--is-active {
          border-bottom: 2px solid var(--primary-stroke-weak-active, #003C3C);
        }

        .icon {
          stroke: #191414;
        }
      }
    }
    .params-setup-markets-root {
      .markets-header {
        padding: 16px 0;
      }
      .markets-data {
        padding: 0;
      }
    }
    .no-params-message {
      padding: 50px 0;
      text-align: center;
    }
    .multiview-event-drawer-game-params-tab--content {
      height: calc(100% - 48px - 32px);
      overflow-y: auto;
      padding: 0 3px;
    }
  }
</style>
