<template>
  <div
    class="team-params-items-root"
  >
    <div
      v-if="selectedTeam === 'both'"
      class="team-name-header"
    >
      {{ teamParams.teamA.name }}
    </div>
    <div
      v-if="selectedTeam === 'teamA' || selectedTeam === 'both'"
      class="team-params-items"
    >
      <GameParamNumberInput
        v-for="param in baseballAwayTeamParams"
        :key="param.mappableKey"
        :param="param"
        :disabled="isCalculatedMode"
        @paramChange="onTeamParamChange"
        @paramIncrease="onTeamParamIncrease"
        @paramDecrease="onTeamParamDecrease"
      />
    </div>
    <div
      v-if="selectedTeam === 'both'"
      class="team-name-header"
    >
      {{ teamParams.teamB.name }}
    </div>
    <div
      class="team-params-items"
      v-if="selectedTeam === 'teamB' || selectedTeam === 'both'"
    >
      <GameParamNumberInput
        v-for="param in baseballHomeTeamParams"
        :key="param.mappableKey"
        :param="param"
        :disabled="isCalculatedMode"
        @paramChange="onTeamParamChange"
        @paramIncrease="onTeamParamIncrease"
        @paramDecrease="onTeamParamDecrease"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import GameParamNumberInput from '@/components/player-setup/common/GameParamNumberInput';

export default {
  emits: [
    'onParamChange',
    'onParamIncrease',
    'onParamDecrease',
  ],
  components: {
    GameParamNumberInput,
  },
  props: {
    teamParams: {
      type: Object,
      required: true,
    },
    selectedTeam: {
      type: String,
      required: false,
      default: '',
    },
    isTooltipRight: {
      type: Boolean,
      default: false,
    },
    isCalculatedMode: {
      type: Boolean,
      default: false,
    },
    globalParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const pitcherAdjustements = computed(() => props.globalParams?.pitcherAdjustments);
    const baseballHomeTeamParams = computed(() => ([
      {
        ...pitcherAdjustements.value?.homeStarting,
      },
      {
        ...pitcherAdjustements.value?.homeRelief,
      },
      {
        ...props.globalParams?.uncertainty?.home,
      },
    ]));
    const baseballAwayTeamParams = computed(() => ([
      {
        ...pitcherAdjustements.value?.awayStarting,
      },
      {
        ...pitcherAdjustements.value?.awayRelief,
      },
      {
        ...props.globalParams?.uncertainty?.away,
      },
    ]));
    const onTeamParamChange = (value, param) => emit('onParamChange', value, param);
    const onTeamParamIncrease = (param) => emit('onParamIncrease', param);
    const onTeamParamDecrease = (param) => emit('onParamDecrease', param);

    return {
      baseballHomeTeamParams,
      baseballAwayTeamParams,
      onTeamParamChange,
      onTeamParamIncrease,
      onTeamParamDecrease,
    };
  },
};
</script>

<style lang="scss">
.team-params-items-root {
  .team-name-header {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
    background-color: $gray300;
    padding: 12px 8px;
  }
  .team-params-items {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 28px;
  }
}
</style>
