<template>
  <div class="multiview-event-drawer-statistics-tab">
    <div class="multiview-event-drawer-statistics-tab__tabs">
      <div
        v-for="tabType in tabTypes"
        :key="tabType"
        :class="[
          'multiview-event-drawer-statistics-tab__tabs-tab',
          { 'multiview-event-drawer-statistics-tab__tabs-tab--is-active': activeTab === tabType },
        ]"
        @click="setActiveTab(tabType)"
      >
        {{ getTabLabel(tabType) }}
      </div>
    </div>
    <Statistics
      v-if="showEventStats"
      :event="event"
    />
    <Players
      v-if="showPlayerStats"
      :event="event"
      :player-props="playerProps"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { includes } from 'lodash';
import sportIds from '@/services/helpers/sports';
import Event from '@/models/event';
import SoccerEvent from '@/models/soccer-event';
import BasketballEvent from '@/models/basketball-event';
import FootballEvent from '@/models/football-event';
import UltimateEvent from '@/models/ultimate-event';
import HockeyEvent from '@/models/hockey-event';
import BaseballEvent from '@/models/baseball-event';
import Statistics from '@/components/trading-ui/common/Statistics';
import Players from '@/components/trading-ui/common/Players';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  SOCCER_ID,
  ULTIMATE_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

const tabTypes = {
  ALL: 'ALL',
  EVENT: 'EVENT',
  PLAYERS: 'PLAYERS',
};

const tabLabelsByType = {
  [tabTypes.ALL]: 'All',
  [tabTypes.EVENT]: 'Event',
  [tabTypes.PLAYERS]: 'Players',
};

export default {
  components: {
    Statistics,
    Players,
  },
  setup() {
    const store = useStore();

    const activeTab = ref(tabTypes.ALL);
    const showEventStats = computed(() => includes([tabTypes.ALL, tabTypes.EVENT], activeTab.value));
    const showPlayerStats = computed(() => includes([tabTypes.ALL, tabTypes.PLAYERS], activeTab.value));
    const setActiveTab = (type) => {
      activeTab.value = type;
    };
    const getTabLabel = (type) => tabLabelsByType[type];

    const sportId = computed(() => store.getters.multiviewDrawerEvent?.sportId);
    const event = computed(() => {
      if (!store.getters.multiviewDrawerEvent) return null;
      switch (sportId.value) {
      case SOCCER_ID:
        return SoccerEvent(store.getters.multiviewDrawerEvent);
      case BASKETBALL_ID:
        return BasketballEvent(store.getters.multiviewDrawerEvent);
      case FOOTBALL_ID:
        return FootballEvent(store.getters.multiviewDrawerEvent);
      case ULTIMATE_ID:
        return UltimateEvent(store.getters.multiviewDrawerEvent);
      case HOCKEY_ID:
        return HockeyEvent(store.getters.multiviewDrawerEvent);
      case BASEBALL_ID:
        return BaseballEvent(store.getters.multiviewDrawerEvent);
      default:
        return Event(store.getters.multiviewDrawerEvent);
      }
    });

    const playerProps = computed(() => store.getters.multiviewPlayerParams);

    return {
      tabTypes,
      activeTab,
      showEventStats,
      showPlayerStats,
      setActiveTab,
      getTabLabel,
      event,
      playerProps,
    };
  },
};
</script>

<style lang="scss">
.multiview-event-drawer-statistics-tab {
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px;
  gap: 16px;
  margin-top: -12px;
  width: 100%;
  height: calc(100% - 52px);

  &__tabs {
    display: flex;
    position: sticky;
    top: 52px;
    background: var(--neutral-bg-default-active, #FFF);
    z-index: 101;

    &-tab {
      flex: 1;
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
      padding: 0 8px;
      gap: 4px;
      user-select: none;
      cursor: pointer;
      border-bottom: 2px solid var(--primary-stroke-weak-active, #F0F0F0);

      &--is-active {
        border-bottom: 2px solid var(--primary-stroke-weak-active, #003C3C);
      }
    }
  }

  .statistics,
  .players {
    flex: 1;
    height: 100%;
  }
}
</style>
