<template>
  <div
    class="markets-per-player-single"
    :class="{'player-highlighted': isPlayerHighlighted }"
  >
    <div class="info-header">
      <div
        class="player-actions"
      >
        <div
          class="player-actions-btn"
          @click="togglePlayerStatsActive"
        >
          <Icon :name="playerStatsActive ? 'chevron-down' : 'chevron-right'" />
          <p v-if="!playerStatsActive">
            Show stats
          </p>
          <p v-else>
            Hide stats
          </p>
        </div>
        <div
          v-if="!isPlayerVoid && hasSubmittedMarkets"
          class="player-actions-btn"
          :class="{ 'suspend-markets__active': !data.isSuspended }"
          @click="togglePlayerSuspend"
        >
          <Icon :name="data.isSuspended ? 'play' : 'pause'" />
          <p>
            {{ data.isSuspended ? 'Unsuspend markets' : 'Suspend markets' }}
          </p>
        </div>
      </div>
    </div>
    <FootballPlayerStats
      v-if="sportId === footballId && playerStatsActive"
      :stats="getPlayerStats(playerMarketInfo)"
    />
    <BasketballPlayerStats
      v-if="sportId === basketballId && playerStatsActive"
      :stats="getPlayerStats(playerMarketInfo)"
      :competition-type="competitionType"
    />
    <BaseballPlayerStats
      v-if="sportId === baseballId && playerStatsActive"
      :stats="getBaseballPlayerStats(playerMarketInfo)"
    />
    <div class="markets-data">
      <div
        v-if="playerHasMarkets"
        class="markets-per-player-list"
      >
        <div
          class="market-item"
          :class="{
            'suspended': market.suspended,
            'void-player-market': isPlayerVoid || market.voided,
          }"
          v-for="market in playerMarkets"
          :key="market.marketCode"
        >
          <div class="market-info">
            <span>{{ market.marketName }}</span>
            <div
              v-if="!market.isLocked && !isPlayerVoid"
              class="suspend-btn"
              :class="{ 'suspend-active': !market.suspended }"
              @click="toggleMarketSuspend(market)"
            >
              <Icon :name="market.suspended ? 'play-circle' : 'pause-circle'" />
            </div>
          </div>
          <div
            v-if="showMarketPrices(market)"
            class="market-prices"
          >
            <!-- MAIN LINE -->
            <div class="market-price">
              <NumberInput
                class="number-input"
                v-if="getMainLine(market)"
                :model-value="getMainLine(market)"
                :readonly="isPricesReadonly"
                :number-of-decimals="1"
              />
            </div>
            <!-- OVER SELECTION -->
            <div class="market-price">
              <NumberInput
                class="number-input"
                v-if="showMarketOverSelection(market)"
                :model-value="getMarketOverSelection(market)"
                :readonly="isPricesReadonly"
                :number-of-decimals="2"
              />
            </div>
          </div>
          <div
            v-if="showYesSelectionMarketPrices(market)"
            class="market-prices"
          >
            <!-- SCORE INDEX -->
            <div class="market-price">
              <NumberInput
                class="number-input"
                v-if="getScoreIndex(market)"
                :model-value="getScoreIndex(market)"
                readonly
              />
            </div>
            <!-- YES SELECTION -->
            <div class="market-price">
              <NumberInput
                class="number-input"
                v-if="showMarketYesSelection(market)"
                :model-value="getMarketYesSelection(market)"
                :readonly="isPricesReadonly"
                :number-of-decimals="2"
              />
            </div>
          </div>
          <div
            v-if="showPointBands(market)"
            class="point-bands"
          >
            <!-- MAIN LINE -->
            <div class="point-band">
              <NumberInput
                class="number-input"
                v-if="getMainLine(market)"
                :model-value="getMainLine(market)"
                readonly
                :number-of-decimals="1"
              />
            </div>
            <!-- POINT BANDS -->
            <div
              v-for="pointBand in getPointBands(market)"
              :key="pointBand.lowerBound"
              class="point-band"
            >
              <div class="point-band-label">
                {{ pointBand.label }}
              </div>
              <NumberInput
                class="number-input"
                :model-value="getSelectionPrice(pointBand.price)"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  filter,
  map,
  find,
  isNaN,
  orderBy,
  pickBy,
  identity,
} from 'lodash';
import { ID as footballId } from '@/services/helpers/football';
import { ID as basketballId } from '@/services/helpers/basketball';
import Icon from '@/components/common/Icon';
import NumberInput from '@/components/common/NumberInput';
import MarketMapper from '@/services/helpers/market-mapper';
import FootballPlayerStats from '@/components/player-setup/setup-by-team/stats/FootballPlayerStats';
import BasketballPlayerStats from '@/components/player-setup/setup-by-team/stats/BasketballPlayerStats';
import BaseballPlayerStats from '@/components/player-setup/setup-by-team/stats/BaseballPlayerStats';
import { formatPlayerName } from '@/services/helpers/player-setup-mapper';
import sportIds from '@/services/helpers/sports';

const { BASEBALL_ID } = sportIds;

export default {
  props: {
    playerMarketInfo: {
      type: Object,
      default: () => {},
    },
    competitionType: {
      type: String,
      default: 'NBA',
    },
    sportId: {
      type: String,
      default: '',
    },
    lineup: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Icon,
    NumberInput,
    FootballPlayerStats,
    BasketballPlayerStats,
    BaseballPlayerStats,
  },
  setup(props) {
    const store = useStore();
    const data = computed(() => props.playerMarketInfo);
    const isPricesReadonly = computed(() => true);
    const playerName = computed(() => formatPlayerName(data.value.playerName));
    const playerHasMarkets = computed(() => !!data.value?.marketCodeDetails?.length);
    const playerMarkets = computed(() => MarketMapper.sortPlayerMarkets(data.value?.marketCodeDetails, props.sportId));
    const isPlayerVoid = computed(() => data.value.playerLocked && !data.value.lineup);
    const marketsInStore = computed(() => data.value?.marketCodeDetails);
    const hasSubmittedMarkets = computed(() => !!filter(marketsInStore.value, (market) => !market.isLocked)?.length);
    const isPlayerSelectedForMarketsModal = computed(() => !!find(store.getters.playersForMarketsPopupList, (playerId) => playerId === data.value.playerId));
    const isPlayerHighlighted = computed(() => data.value?.playerId === store.getters.highlightedPlayer);
    const playerStatsActive = ref(false);
    const checkIsMarketDisabled = (marketCode) => {
      const addedMarket = find(data.value?.marketCodeDetails, { marketCode });
      if (!addedMarket) return false;
      return !addedMarket.isLocked;
    };
    const availableMarkets = computed(() => {
      const markets = map(store.getters.availableMarkets, (market) => ({
        value: market.marketCode,
        label: market.marketName,
        disabled: checkIsMarketDisabled(market.marketCode),
      }));
      return markets;
    });

    const getSelectionPrice = (price) => {
      let value = price.probability * 100;
      if (isNaN(value)) return null;

      value = value % 1 !== 0
        ? parseFloat(value.toFixed(1))
        : parseInt(value, 10);

      return value;
    };

    const getMarketOverSelection = (market) => {
      const price = find(market.selections, { selectionType: { selectionCode: 'OVER' } })?.price;
      if (!price) return null;

      return getSelectionPrice(price);
    };

    const isOverSelectionError = (market) => {
      const overSelection = find(market.selections, { selectionType: { selectionCode: 'OVER' } });

      return overSelection?.isError;
    };

    const getMarketYesSelection = (market) => {
      const price = find(market.selections, { selectionType: { selectionCode: 'YES' } })?.price;
      if (!price) return null;

      return getSelectionPrice(price);
    };

    const isMarketYesSelectionError = (market) => {
      const yesSelection = find(market.selections, { selectionType: { selectionCode: 'YES' } });

      return yesSelection.isError;
    };

    const getMainLine = (market) => {
      if (!market?.marketSummary?.isMainLine) return null;
      const mainLine = market?.marketSummary?.mainLine;

      return mainLine;
    };

    const getScoreIndex = (market) => market?.params?.SCORE_INDEX;

    const showPointBandLabel = (pointBand, hasUpperBound = true) => (
      (pointBand.lowerBound
        || pointBand.lowerBound === 0)
      && hasUpperBound ? pointBand.upperBound : true
    );

    const getPointBands = (market) => {
      if (!market.selections?.length) return null;
      let pointBands = map(market.selections, (selection) => ({
        selectionId: selection.selectionId,
        selectionCode: selection.selectionType?.selectionCode,
        lowerBound: selection.selectionType?.params?.LOWER_BOUND,
        upperBound: selection.selectionType?.params?.UPPER_BOUND,
        price: selection.price,
      }));

      pointBands = orderBy(pointBands, ['lowerBound']);

      // Setting point band labels
      pointBands = map(pointBands, (pointBand) => ({
        ...pointBand,
        label: showPointBandLabel(pointBand) ? `${pointBand.lowerBound} to ${pointBand.upperBound}` : null,
      }));

      // Setting label for lowest lower bound
      const lowest = pointBands[0];
      lowest.label = showPointBandLabel(lowest) ? `${lowest?.lowerBound}-${lowest?.upperBound}` : null;

      // Setting label for highest lower bound
      const highest = pointBands[pointBands.length - 1];
      highest.label = showPointBandLabel(highest, false) ? `${highest?.lowerBound}+` : null;

      return pointBands;
    };
    const showMarketOverSelection = (market) => getMarketOverSelection(market) || getMarketOverSelection(market) === 0;
    const showMarketYesSelection = (market) => getMarketYesSelection(market) || getMarketYesSelection(market) === 0;
    const showPointBands = (market) => market.marketCode === 'PLAYER_POINT_BANDS' && (getPointBands(market) || getMainLine(market));
    const showMarketPrices = (market) => (showMarketOverSelection(market) || getMainLine(market)) && !showPointBands(market) && !showMarketYesSelection(market);
    const showYesSelectionMarketPrices = (market) => (showMarketYesSelection(market) || getScoreIndex(market)) && !showPointBands(market) && !showMarketOverSelection(market);

    const togglePlayerStatsActive = () => { playerStatsActive.value = !playerStatsActive.value; };

    const toggleMarketSuspend = (market) => {
      const storeAction = market.suspended
        ? 'unsuspendPlayerMarkets' : 'suspendPlayerMarkets';
      store.dispatch(storeAction, {
        playerId: props.playerMarketInfo.playerId,
        teamLabel: props.teamLabel,
        marketCodes: [
          market,
        ],
      });
    };

    const togglePlayerSuspend = () => {
      const suspended = props.playerMarketInfo.isSuspended;
      const storeAction = suspended
        ? 'unsuspendPlayerMarkets'
        : 'suspendPlayerMarkets';

      const markets = suspended
        ? props.playerMarketInfo.marketCodeDetails
        : filter(props.playerMarketInfo.marketCodeDetails, { suspended: false });

      store.dispatch(storeAction, {
        playerId: props.playerMarketInfo.playerId,
        teamLabel: props.teamLabel,
        marketCodes: markets,
      });
    };

    const getPlayerStats = ({ playerIndex }) => find(props.lineup, (player) => player.playerIndex === playerIndex)?.playerStats;
    const getBaseballPlayerStats = ({ playerIndex }) => {
      const playerStats = find(props.lineup, (player) => player.playerIndex === playerIndex)?.playerStats;
      return pickBy({
        providerId: playerStats?.providerId,
        pitcher: playerStats?.pitcherDetails,
        batter: playerStats?.batterDetails,
      }, identity);
    };

    return {
      footballId,
      basketballId,
      baseballId: BASEBALL_ID,
      data,
      playerName,
      playerHasMarkets,
      availableMarkets,
      isPlayerVoid,
      hasSubmittedMarkets,
      marketsInStore,
      isPlayerSelectedForMarketsModal,
      playerMarkets,
      playerStatsActive,
      isPlayerHighlighted,
      isPricesReadonly,
      showMarketOverSelection,
      showMarketYesSelection,
      getMarketOverSelection,
      getMarketYesSelection,
      getMainLine,
      showMarketPrices,
      getScoreIndex,
      showYesSelectionMarketPrices,
      showPointBands,
      getPointBands,
      getSelectionPrice,
      togglePlayerStatsActive,
      isOverSelectionError,
      isMarketYesSelectionError,
      toggleMarketSuspend,
      togglePlayerSuspend,
      getPlayerStats,
      getBaseballPlayerStats,
    };
  },
};
</script>

<style lang="scss">
  .markets-per-player-single {
    border: 2px solid transparent;
    &.player-highlighted {
      border: 2px solid $primary200;
      border-radius: 4px;
    }
    .info-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 52px;
        position: relative;

        .player-actions {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 5px;

          .player-actions-btn {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 5px 10px;
            cursor: pointer;

            svg path {
              stroke: $black;
            }

            &.suspend-markets__active {
              .icon svg path {
                fill: $error500;
                stroke: $error500;
              }
              p {
                color: $error500;
              }

            }
          }
        }
    }
    .markets-data {
        .markets-per-player-list {
          display: flex;
          flex-wrap: wrap;
          align-items: stretch;
          gap: 30px;
          padding: 32px 0;
          max-width: 100%;
          overflow: hidden;

          .market-item {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            flex-wrap: wrap;
            gap: 6px;
            padding: 4px;
            border-radius: 4px;
            flex: 0 0 auto;

            .market-info {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 13px;
            }

            .market-prices {
              width: 100%;
              display: flex;
              align-items: center;
            }
            .point-bands {
              display: flex;
              align-items: flex-end;
              flex-wrap: nowrap;

              .point-band {
                display: flex;
                flex-direction: column;

                .point-band-label {
                  font-size: 10px;
                  color: #6D6D6D;
                }

              }
            }

            .market-price,
            .point-band {
              .number-input {
                input {
                  width: 48px;
                  height: 33px;
                  padding: 0 4px;
                  text-align: center;
                  font-size: 14px;
                  border-radius: 0;
                  border-right-width: 0;
                  position: relative;
                  z-index: 1;

                  &:focus {
                    z-index: 2;
                  }
                }
              }
              &:first-child {
                .number-input input {
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                }
              }
              &:last-child {
                .number-input input {
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                  border-right-width: 1px;
                }
              }
            }

            &.suspended {
              background-color: $error50;
            }

            &.void-player-market {
              background-color: $gray400;
            }

            .suspend-btn {
              width: 24px;
              height: 24px;
              min-width: 24px;
              min-height: 24px;
              border: 1px solid $gray400;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $white;
              cursor: pointer;

              &.suspend-active {
                .icon svg path {
                  fill: $error500;
                }
              }
            }
          }
        }
        .no-data-message {
            width: 55%;
            margin: 0 auto;
            text-align: center;
            padding: 32px 0;
            p {
                line-height: 17px;
                text-align: center;
                color: $gray700;
            }
            .button {
                margin-top: 16px;
                span {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }

    .basketball-player-stats,
    .football-player-stats {
      grid-template-columns: repeat(3, auto);
    }
  }
</style>
