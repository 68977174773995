<template>
  <div class="trading-game-params-root">
    <div class="global-params-header">
      <div class="global-param-header-single">
        <div class="global-param-header-title">
          Start RHO (Air density)
        </div>
        <div class="global-param-header-content">
          <GameParamNumberInput
            :param="rhoStart"
            @paramChange="onGlobalParamChange"
            @paramIncrease="onGlobalParamIncrease"
            @paramDecrease="onGlobalParamDecrease"
          />
          <GameParamNumberInput
            :param="rhoStartManual"
            @paramChange="onGlobalParamChange"
            @paramIncrease="onGlobalParamIncrease"
            @paramDecrease="onGlobalParamDecrease"
          />
        </div>
      </div>
      <div class="global-param-header-single">
        <div class="global-param-header-title">
          End RHO (Air density)
        </div>
        <div class="global-param-header-content">
          <GameParamNumberInput
            :param="rhoEnd"
            @paramChange="onGlobalParamChange"
            @paramIncrease="onGlobalParamIncrease"
            @paramDecrease="onGlobalParamDecrease"
          />
          <GameParamNumberInput
            :param="rhoEndManual"
            @paramChange="onGlobalParamChange"
            @paramIncrease="onGlobalParamIncrease"
            @paramDecrease="onGlobalParamDecrease"
          />
        </div>
      </div>
      <div class="global-param-header-single">
        <div class="global-param-header-title">
          Home run rate
        </div>
        <div class="global-param-header-content">
          <GameParamNumberInput
            :param="homeRunRate"
            @paramChange="onGlobalParamChange"
            @paramIncrease="onGlobalParamIncrease"
            @paramDecrease="onGlobalParamDecrease"
          />
        </div>
      </div>
    </div>
    <div class="global-params-header">
      <div class="global-param-header-single park-select-root">
        <div class="global-param-header-content">
          <div class="park-id-dropdown-root">
            <span class="park-id-label">ParkId</span>
            <Dropdown
              class="park-id-dropdown"
              :label="parkId?.toString() || 'Select ParkId'"
              :disabled="isCalculatedMode"
            >
              <DropdownItem
                v-for="park in parkIdValues"
                :key="park.parkId"
                @click="selectParkId(park)"
                clickable
              >
                {{ park.parkId + ' - ' + park.parkName }}
              </DropdownItem>
            </Dropdown>
          </div>
        </div>
      </div>
      <div class="global-param-header-toggle">
        <Toggle
          :model-value="useHomeRunRate?.value"
          :disabled="isCalculatedMode"
          @update:model-value="value => { onGlobalParamChange(value, useHomeRunRate) }"
        />
        <span>{{ useHomeRunRate?.label }}</span>
      </div>
    </div>
    <Button
      :icon="showAllGameParams ? 'chevron-up' : 'chevron-down'"
      class="show-more-params-button"
      @click="toggleShowAllGameParams"
    >
      {{ showAllGameParams ? 'Show less' : 'Show more' }}
    </Button>
    <div
      v-if="showAllGameParams"
      class="additional-global-params"
    >
      <div class="projections-items-root">
        <div
          v-for="(projection, index) in projectionsSetup"
          :key="index"
          class="projections-item-single"
        >
          <div class="projection-item-title">
            {{ projection.title }}
          </div>
          <div class="projection-item-content">
            <GameParamNumberInput
              v-for="(param) in projection.params"
              :key="param.shortName"
              :param="param"
              @paramChange="onGlobalParamChange"
              @paramIncrease="onGlobalParamIncrease"
              @paramDecrease="onGlobalParamDecrease"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { filter } from 'lodash';
import { createBaseballGlobalParams } from '@/components/player-setup/params-helper';
import GameParamNumberInput from '@/components/player-setup/common/GameParamNumberInput';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import Toggle from '@/components/common/Toggle';
import Button from '@/components/common/Button';

export default {
  emits: [
    'onParamChange',
    'onParamIncrease',
    'onParamDecrease',
  ],
  props: {
    globalParams: {
      type: Object,
      default: () => {},
    },
    isCalculatedMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GameParamNumberInput,
    Dropdown,
    DropdownItem,
    Toggle,
    Button,
  },
  setup(props, { emit }) {
    const store = useStore();
    const gameParamsConfig = computed(() => createBaseballGlobalParams(props.isCalculatedMode));
    const gameParams = computed(() => props.globalParams);
    const showAllGameParams = ref(false);
    // Game header params
    // RHO Start
    const rhoStart = computed(() => gameParams.value?.weatherData?.rhoStart);
    const rhoStartManual = computed(() => gameParams.value?.weatherData?.rhoStartManual);

    // RHO end
    const rhoEnd = computed(() => gameParams.value?.weatherData?.rhoEnd);
    const rhoEndManual = computed(() => gameParams.value?.weatherData?.rhoEndManual);
    // Home run rate
    const homeRunRate = computed(() => gameParams.value?.homeRunRate);
    const useHomeRunRate = computed(() => gameParams.value?.useHomeRunRate);

    // Park ID
    const parkId = computed(() => gameParams.value?.parkId?.value);
    const parkIdValues = computed(() => filter(store.getters.allBaseballParks, (park) => parkId.value !== park.parkId));

    // Additional params (params on show more)
    const projections = computed(() => gameParams.value?.projections);
    const mapProjectionsParams = (param) => Object.keys(param).map((key) => param[key]);
    const projectionsSetup = computed(() => ([
      {
        title: gameParamsConfig.value?.projections?.league?.title?.text,
        params: mapProjectionsParams(projections.value?.league),
      },
      {
        title: gameParamsConfig.value?.projections?.park?.title?.text,
        params: mapProjectionsParams(projections.value?.park),
      },
      {
        title: gameParamsConfig.value?.projections?.homeTeam?.title?.text,
        params: mapProjectionsParams(projections.value?.homeTeam),
      },
      {
        title: gameParamsConfig.value?.projections?.awayTeam?.title?.text,
        params: mapProjectionsParams(projections.value?.awayTeam),
      },
      {
        title: gameParamsConfig.value?.projections?.leftieToLeftie?.title?.text,
        params: mapProjectionsParams(projections.value?.leftieToLeftie),
      },
      {
        title: gameParamsConfig.value?.projections?.leftieToRightie?.title?.text,
        params: mapProjectionsParams(projections.value?.leftieToRightie),
      },
      {
        title: gameParamsConfig.value?.projections?.rightieToLeftie?.title?.text,
        params: mapProjectionsParams(projections.value?.rightieToLeftie),
      },
      {
        title: gameParamsConfig.value?.projections?.rightieToRightie?.title?.text,
        params: mapProjectionsParams(projections.value?.rightieToRightie),
      },
    ]));

    const onGlobalParamChange = (value, param) => emit('onParamChange', value, param);
    const onGlobalParamIncrease = (param) => emit('onParamIncrease', param);
    const onGlobalParamDecrease = (param) => emit('onParamDecrease', param);

    const selectParkId = (park) => {
      onGlobalParamChange(park.parkId, gameParams.value.parkId);
    };

    const toggleShowAllGameParams = () => {
      showAllGameParams.value = !showAllGameParams.value;
    };
    return {
      showAllGameParams,
      rhoStart,
      rhoStartManual,
      rhoEnd,
      rhoEndManual,
      homeRunRate,
      useHomeRunRate,
      parkId,
      parkIdValues,
      projectionsSetup,
      onGlobalParamChange,
      onGlobalParamIncrease,
      onGlobalParamDecrease,
      toggleShowAllGameParams,
      selectParkId,
    };
  },
};
</script>

<style lang="scss">
.trading-game-params-root {
  width: 100%;
  .global-params-header {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 8px;
    padding-top: 0;
    .global-param-header-single {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 8px;

      &.park-select-root {
        justify-content: flex-start;
      }
      .global-param-header-title {
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
      }
      .global-param-header-content {
        display: flex;
        align-items: flex-end;
        gap: 8px;
        .park-id-dropdown-root {
          .park-id-label {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #6D6D6D;
            text-transform: uppercase;
            font-size: 10px;
            line-height: 12px;
            font-weight: 600;
          }
          .park-id-dropdown {
            display: flex;
            flex-direction: column;
            .dropdown-menu {
              max-height: 300px;
              overflow: auto;
              bottom: unset;
              top: 100%;
              .dropdown-item {
                padding: 10px 12px;
              }
            }
          }
        }
      }
    }
    .global-param-header-toggle {
      display: flex;
      align-items: flex-start;
      gap: 4px;
      margin-left: 16px;
    }
  }
  .show-more-params-button {
      width: 100%;
      margin-left: auto;
      margin-top: auto;
      justify-content: center;
      .icon svg path {
        stroke: $black;
      }
    }
  .additional-global-params {
    background-color: $gray300;
    padding: 16px;
    .projections-items-root {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      justify-content: space-between;
      .projections-item-single {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .projection-item-title {
          font-size: 10px;
          font-weight: 600;
          line-height: 12px;
          color: $primary500;
          text-transform: uppercase;
        }
        .projection-item-content {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
        }
      }
    }
  }
}
</style>
