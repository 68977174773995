<template>
  <div class="multiview-event-drawer-markets-tab">
    <AlternateMarkets
      :markets="alternateMarkets"
      :event-id="event?.eventId || ''"
      :is-usa-view="event?.isUsaView || true"
      :sport-id="event?.sport?.sportId || ''"
      :sport-label="event?.sport?.sportLabel || ''"
      :competition-id="event?.competition?.competitionId || ''"
      :home-team="event?.competitors[0] || {}"
      :away-team="event?.competitors[1] || {}"
      :is-prematch="false"
      :double-space="false"
      :markets-display-configuration="marketsDisplayConfiguration"
      :multi-view="true"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { orderBy, filter } from 'lodash';
import AlternateMarkets from '../../singleview/markets/AlternateMarkets';

export default {
  components: {
    AlternateMarkets,
  },
  setup() {
    const store = useStore();
    const event = computed(() => store.getters.multiviewDrawerEvent);
    const tradingEventMarkets = computed(() => {
      if (event.value?.matchState === 'LIVE') {
        return filter(store.getters.tradingEventMarkets, (market) => market.inPlay && market.resultingStatus !== 'RESULTED');
      }
      return store.getters.tradingEventMarkets;
    });

    const alternateMarkets = computed(() => filter(tradingEventMarkets.value,
      (market) => !(market.marketType.params.SELECTIONS === 2
      && (market.marketCode === 'RESULT'
      || ((market.marketCode === 'POINT_HANDICAP' || market.marketCode === 'POINT_OVER_UNDER'
      || market.marketCode === 'GOAL_HANDICAP' || market.marketCode === 'GOAL_OVER_UNDER'
      || market.marketCode === 'RUN_HANDICAP' || market.marketCode === 'RUN_OVER_UNDER')
      && market.marketSummary?.isMainLine)))));
    const marketsDisplayConfiguration = computed(() => orderBy(filter(store.getters.displayMarketsConfiguration,
      (market) => market.active), 'position'));

    return {
      event,
      alternateMarkets,
      marketsDisplayConfiguration,
    };
  },
};
</script>

<style lang="scss">
.multiview-event-drawer-markets-tab {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 273px);
}
</style>
