<template>
  <div
    class="game-params-items-root"
  >
    <div
      v-for="param in globalParams"
      :key="param.key"
      class="game-param-single"
      :class="{
        'auto-width': param.type === 'boolean' || param.type === 'text-toggle',
        'has-divider': param.hasDivider,
      }"
    >
      <div
        v-if="param.hasDivider"
        class="divider"
      />
      <div
        v-if="param.type !== 'boolean'"
        class="game-param-single-header"
      >
        <div class="short-name">
          {{ param.shortName }}
        </div>
        <Tooltip
          :text="param.longName"
          :left="!isTooltipRight"
          :right="isTooltipRight"
        >
          <Icon
            class="game-param-single-name-icon"
            name="info"
          />
        </Tooltip>
      </div>
      <NumberInput
        v-if="!param.type"
        class="game-param-single-input"
        :class="{ 'is-error': param.isError }"
        :model-value="param.value"
        :is-error="param.isError"
        :error-message="param.errorMessage"
        :accept-negative="(param.minValue < 0)"
        :readonly="isCalculatedMode"
        :number-of-decimals="param.numberOfDecimals || 3"
        :has-arrows="true"
        @update:modelValue="onGlobalParamChange($event, param)"
        @onKeyUp="onGlobalParamIncrease(param)"
        @onKeyDown="onGlobalParamDecrease(param)"
      />
      <div
        v-if="param.type === 'boolean'"
        class="game-param-header-toggle"
      >
        <Toggle
          :model-value="param.value"
          :disabled="isCalculatedMode"
          @update:model-value="value => { onGlobalParamChange(value, param) }"
        />
        <span>{{ param.longName }}</span>
      </div>
      <TextToggle
        v-if="param.type === 'text-toggle'"
        :model-value="param.value"
        :disabled="isCalculatedMode"
        :toggle-items="param.options"
        @update:model-value="value => { onGlobalParamChange(value, param) }"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import NumberInput from '@/components/common/NumberInput';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';
import Toggle from '@/components/common/Toggle';
import TextToggle from '@/components/common/TextToggle';

export default {
  emits: [
    'onParamChange',
    'onParamIncrease',
    'onParamDecrease',
  ],
  components: {
    NumberInput,
    Tooltip,
    Icon,
    Toggle,
    TextToggle,
  },
  props: {
    globalParams: {
      type: Array,
      default: () => [],
    },
    isTooltipRight: {
      type: Boolean,
      default: false,
    },
    isCalculatedMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const params = computed(() => props.globalParams);
    const onGlobalParamChange = (value, param) => emit('onParamChange', value, param);
    const onGlobalParamIncrease = (param) => emit('onParamIncrease', param);
    const onGlobalParamDecrease = (param) => emit('onParamDecrease', param);

    return {
      params,
      onGlobalParamChange,
      onGlobalParamIncrease,
      onGlobalParamDecrease,
    };
  },
};
</script>

<style lang="scss">
.game-params-items-root {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  .game-param-single {
    width: 67px;
    &.is-error {
      width: 90px;
    }
    &.auto-width {
      width: auto;
    }
    &.has-divider {
      width: calc(67px + 38px);
      padding-left: 38px;
      position: relative;
    }
    .divider {
      width: 1px;
      height: 100%;
      background-color: $gray400;
      margin: 0 27px 0 11px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .game-param-single-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .short-name {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $gray800;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 2px;
        font-weight: 600;
      }
      .game-param-single-name-icon {
        cursor: pointer;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    .game-param-single-input {
      width: 100%;
      input {
        padding: 5px 8px;
      }
    }
    .game-param-header-toggle {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-left: 16px;
    }
  }
}
</style>
