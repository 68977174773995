<template>
  <div
    v-if="multiviewEventExists"
    class="multiview-event-drawer-info-tab"
  >
    <div class="multiview-event-drawer-info-tab__box">
      <div class="multiview-event-drawer-info-tab__scoreboard">
        <Scoreboard
          :event="event"
        />
        <PlayByPlay
          v-if="showPlayByPlay"
          :event="event"
        />
      </div>
    </div>
    <div class="multiview-event-drawer-info-tab__box">
      <Heading
        as="h3"
        size="xs"
      >
        Event information
      </Heading>
      <div class="multiview-event-drawer-info-tab__content">
        <div class="multiview-event-drawer-info-tab__row">
          <div class="multiview-event-drawer-info-tab__input">
            <div class="multiview-event-drawer-info-tab__input-label">
              Event ID
            </div>
            <div class="multiview-event-drawer-info-tab__input-field">
              <ActionInput
                class="event-details__header-input-field"
                icon="copy"
                message="Event ID copied"
                :value="event.id"
                @click="copyEventId"
              />
            </div>
          </div>
          <div class="multiview-event-drawer-info-tab__input">
            <div class="multiview-event-drawer-info-tab__input-label">
              Market template
            </div>
            <div class="multiview-event-drawer-info-tab__input-field">
              <ActionInput
                class="event-details__header-input-field"
                icon="external"
                :value="sportTemplateName"
                @click="goToSportTemplate"
              />
            </div>
          </div>
        </div>
        <div class="multiview-event-drawer-info-tab__row">
          <div class="multiview-event-drawer-info-tab__input">
            <div class="multiview-event-drawer-info-tab__input-label">
              Pricing
            </div>
            <div class="multiview-event-drawer-info-tab__input-field">
              <ActionInput
                class="event-details__header-input-field"
                :value="priceLabel"
                no-hover
              />
            </div>
          </div>
          <div class="multiview-event-drawer-info-tab__input">
            <div class="multiview-event-drawer-info-tab__input-label">
              Approved
            </div>
            <div class="multiview-event-drawer-info-tab__input-field">
              <ActionInput
                class="event-details__header-input-field"
                :value="approvedLabel"
                no-hover
              />
            </div>
          </div>
        </div>
        <div class="multiview-event-drawer-info-tab__row">
          <div class="multiview-event-drawer-info-tab__input">
            <div class="multiview-event-drawer-info-tab__input-label">
              Mapping
            </div>
            <div class="multiview-event-drawer-info-tab__input-field">
              <ActionInput
                class="event-details__header-input-field"
                :value="mappedLabel"
                no-hover
              />
            </div>
          </div>
          <div class="multiview-event-drawer-info-tab__input">
            <div class="multiview-event-drawer-info-tab__input-label">
              Event coverage
            </div>
            <div class="multiview-event-drawer-info-tab__input-field">
              <ActionInput
                class="event-details__header-input-field"
                :value="coveredLabel"
                no-hover
              />
            </div>
          </div>
        </div>
        <div class="multiview-event-drawer-info-tab__row">
          <div class="multiview-event-drawer-info-tab__highlight-control">
            <Toggle
              :model-value="isHighlighted"
              @update:model-value="toggleHighlight"
              :disabled="disabled"
            />
            <p>Highlight event</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { isPlayByPlayDisplayable } from '@/services/helpers/play-by-play';
import Heading from '@/components/common/Heading';
import Toggle from '@/components/common/Toggle';
import ActionInput from '@/components/common/ActionInput';
import PlayByPlay from '@/components/common/PlayByPlay';
import Scoreboard from '@/components/trading-ui/common/Scoreboard';

export default {
  components: {
    Heading,
    Toggle,
    ActionInput,
    PlayByPlay,
    Scoreboard,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const sportId = computed(() => props.event?.sportId);
    const sportTemplateId = computed(() => props.event?.sportTemplate?.id || '');
    const sportTemplateName = computed(() => props.event?.sportTemplate?.name || 'N/A');
    const multiviewEventExists = computed(() => !!props.event.id);
    const priceLabel = computed(() => (props.event?.hasInternalPrices ? 'Huddle' : 'None'));
    const approvedLabel = computed(() => (props.event?.pricesApproved ? 'Pricing approved' : 'Pricing not approved'));
    const mappedLabel = computed(() => (props.event?.isMapped ? 'Mapped' : 'Not mapped'));
    const coveredLabel = computed(() => (props.event?.isCovered ? 'Yes' : 'No'));
    const isHighlighted = computed(() => props.event.isHighlighted);
    const canEdit = computed(() => store.getters.operator === 'Huddle');
    const isBooked = computed(() => !!store.getters.multiviewDrawerEvent.booked?.nodes?.length);
    const isProcessing = computed(() => store.getters.multiviewDrawerProcessing);
    const isDisabled = computed(() => !isBooked.value || isProcessing.value);

    const copyEventId = () => {
      if (!navigator?.clipboard) return;
      navigator.clipboard.writeText(props.event.id);
    };
    const goToSportTemplate = () => {
      if (!sportTemplateId.value) return;
      const { href } = router.resolve({
        name: 'sport-template',
        params: {
          id: sportTemplateId.value,
        },
      });
      window.open(href, '_blank');
    };
    const toggleHighlight = () => {
      store.dispatch('toggleMultiviewDrawerEventHighlight');
    };

    const showPlayByPlay = computed(() => isPlayByPlayDisplayable(props.event));

    return {
      sportId,
      sportTemplateId,
      sportTemplateName,
      multiviewEventExists,
      priceLabel,
      approvedLabel,
      mappedLabel,
      coveredLabel,
      isHighlighted,
      canEdit,
      isBooked,
      isDisabled,
      copyEventId,
      goToSportTemplate,
      toggleHighlight,
      showPlayByPlay,
    };
  },
};
</script>

<style lang="scss">
.multiview-event-drawer-info-tab {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;

  &__title {
    color: #191414;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 23.58px;
    height: 33px;
    margin-bottom: 16px;
  }

  &__content {
    margin-top: 16px;
  }

  &__scoreboard {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__row {
    display: flex;
    gap: 12px;
    width: 100%;
    margin-bottom: 16px;
  }

  &__input {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;

    &-label {
      color: #6D6D6D;
      font-family: 'Rubik', sans-serif;
      font-weight: 600;
      font-size: 10px;
      line-height: 11.85px;
      text-transform: uppercase;
    }
  }

  &__highlight-control {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: #FAFAFA;
    width: 100%;
    height: 32px;
    padding: 0 8px;
    border-radius: 8px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #191414;
  }

  .multiview-event-drawer-info-tab__pricing-select {
    position: relative;
    user-select: none;
    height: 44px;

    .multiview-event-drawer-info-tab__pricing-select-head {
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      color: #191414;
      stroke: #191414;
      gap: 8px;
      background-color: #fff;
      padding: 8px 12px;
      border: 1px solid #F0F0F0;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      transition: box-shadow 100ms ease-in;
      cursor: pointer;

      &:hover {
        background: #FAFAFA;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .multiview-event-drawer-info-tab__pricing-select-head-label {
      text-overflow: ellipsis;
      overflow: hidden;
      // white-space: nowrap;
    }

    .multiview-event-drawer-info-tab__pricing-select-body {
      position: absolute;
      top: calc(100% + 4px);
      min-width: 100%;
      border-radius: 4px;
      border: 1px solid #DDD;
      background: #FFF;
      z-index: 500;
      padding: 4px 0;
    }

    .multiview-event-drawer-info-tab__pricing-select-item {
      background: inherit;
      display: flex;
      align-items: center;
      height: 33px;
      padding: 8px;
      gap: 8px;
      cursor: pointer;

      &:not(.multiview-event-drawer-info-tab__pricing-select-item--is-disabled) {
        &:hover,
        &:focus {
          background-color: $rowsPerPageDropdownItemHoverBackgroundColor;
        }
      }

      &.multiview-event-drawer-info-tab__pricing-select-item--is-disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    &.multiview-event-drawer-info-tab__pricing-select--is-disabled {
      cursor: not-allowed;

      .multiview-event-drawer-info-tab__pricing-select-head {
        background: #F0F0F0;
        border: 1px solid #CDCDCD;
      }

      .multiview-event-drawer-info-tab__pricing-select-head-label {
        opacity: 0.5;
      }

      .multiview-event-drawer-info-tab__pricing-select-head-icon {
        opacity: 0.4;
      }
    }
  }
}
</style>
